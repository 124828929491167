import _isEmpty from 'underscore/cjs/isEmpty.js'

import {
  removeRichTextInPosts,
  handleRequestSingle,
  generatePostStructure
} from '../helpers/functions'

import {
  postsByCategoryQuery,
  postsQuery,
  postQuery,
  latestPostQuery,
  categoryQuery,
  authorDataQuery
} from './schema.js'

class Service {
  constructor () {
    const { $apolloClient } = useNuxtApp()
    this.client = $apolloClient
    this.data = {}
  }

  getPost = async ({ slug, preview = false } = {}) => {
    const response = await this.client.query({
      query: postQuery,
      variables: {
        slug,
        preview,
        limit: 1
      }
    }).then(res => {
      const data = res?.data

      if (data?.post.total === 0) {
        return null
      }

      const post = data?.post?.items?.[0]
      const latestNews = data.latestNews.items.map(item => removeRichTextInPosts(item))

      return {
        post,
        latestNews
      }
    })

    return response
  }

  getPostsByCategory = async ({ categories = [], limit = 5, preview = false, offset = 0 } = {}) => {
    try {
      const response = await this.client.query({
        query: postsByCategoryQuery({
          categories,
          limit,
          preview,
          offset
        }),
        variables: {}
      }).then(res => {
        const categoryMap = {
          top_news: 'Top News',
          insurance: 'Insurance',
          loans_and_credit: 'Loans and Credit',
          utilities: 'Utilities',
          savings_and_super: 'Savings and Super',
          life_events: 'Life events'
        }

        const data = JSON.parse(JSON.stringify(res?.data))

        if (_isEmpty(data)) {
          return {}
        }

        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            data[key] = (() => {
              const tempData = data[key]

              return {
                title: getKey(key, categoryMap),
                total: tempData?.total || 0,
                ...generatePostStructure({
                  items: tempData.items,
                  hasFeature: !offset
                })
              }
            })()
          }
        }

        return data
        // eslint-disable-next-line no-console
      }).catch(error => console.log(error))

      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  getPostsByAuthor = async ({ author, slug, limit = 4, preview = false, offset = 0 } = {}) => {
    const response = await this.client.query({
      query: postsQuery,
      variables: {
        author,
        slug_not: slug,
        limit,
        preview,
        offset
      }
    }).then(res => {
      const posts = JSON.parse(JSON.stringify(res?.data?.posts))

      posts.items = posts.items.map(item => removeRichTextInPosts(item, {
        layout: 'vertical'
      }))

      if (posts.total === 0) {
        return []
      }

      return posts
    })

    return response
  }

  getAuthorData = async ({ slug, postLimit = 4, preview = false } = {}) => {
    try {
      const response = await this.client.query({
        query: authorDataQuery,
        variables: {
          slug,
          limit: 1,
          postLimit,
          preview
        }
      }).then(res => {
        const data = JSON.parse(JSON.stringify(res.data))

        const author = data.author?.items?.[0] ?? {}

        if (_isEmpty(author)) {
          return {}
        }

        const posts = {
          total: data.posts?.total,
          ...generatePostStructure({
            items: data.posts.items,
            hasFeature: true
          })
        }

        return {
          author,
          posts
        }
      // eslint-disable-next-line no-console
      }).catch(error => console.log(error))

      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  getRelatedPosts = async ({ slug, tags, category, postBySlug = false, preview = false, limit = 4, offset = 0 } = {}) => {
    const params = removeFalseyObj({
      slug_not: slug,
      tags,
      category
    })

    if (postBySlug && !_isEmpty(tags)) {
      delete params.category

      params.tags = tags
    }

    const response = await this.client.query({
      query: postsQuery,
      variables: {
        ...params,
        limit,
        preview,
        offset
      }
    }).then(res => {
      const posts = JSON.parse(JSON.stringify(res?.data?.posts))

      posts.items = posts.items.map(item => removeRichTextInPosts(item, { layout: 'vertical' }))

      return posts
    }).catch(err => {
      return err
    })

    return response
  }

  getLatestNews = async ({ slugNotIn = [], preview = false } = {}) => {
    const response = await this.client.query({
      query: latestPostQuery,
      variables: {
        slug_not_in: slugNotIn || [],
        preview
      }
    }).then(res => {
      const posts = res?.data?.posts
        ? JSON.parse(JSON.stringify(res?.data?.posts))
        : {}

      posts.items = posts.items.map(item => removeRichTextInPosts(item, { layout: 'small-tile' }))

      return posts?.items
    })

    return response
  }

  getCategoryBySlug = async (slug, preview = false) => {
    const response = await this.client.query({
      query: categoryQuery,
      variables: {
        slug,
        preview,
        limit: 1
      }
    }).then(res => {
      res = handleRequestSingle(res, 'category')

      return res?.data
      // eslint-disable-next-line no-console
    }).catch(error => console.log(error))

    return response
  }
}

export default Service
