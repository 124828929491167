import gql from 'graphql-tag'

import _isObject from 'underscore/cjs/isObject.js'
import _isEmpty from 'underscore/cjs/isEmpty.js'

import {
  postsFragment,
  postFragment,
  authorFragment
} from './fragment'

import {
  EXPERT_EASE_CATEGORIES
} from '~/constants/pages/contentful'

const generatePostsByCategory = ({ categories, limit = 5, offset } = {}) => {
  let query = ''
  let tempLimit = limit

  if (!_isEmpty(categories)) {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i]

      if (_isObject(limit)) {
        tempLimit = getKey(strToSnakeCase(category), limit)
      }

      const isCategory = Object.keys(EXPERT_EASE_CATEGORIES).includes(category)

      query += `
        ${strToSnakeCase(category)}: newsCollection(
          order: [sys_firstPublishedAt_DESC]
          limit: ${tempLimit}
          preview: false,
          skip: ${offset}
          where: {
            ${isCategory ? `category: "${category}"` : ''}
            ${category === 'Top News' ? 'featured: true' : ''}
          }
        ) {
          total
          items {
            ...postsFragment
          }        
        }
      `
    }
  }

  return query
}

export const postsByCategoryQuery = ({ limit, categories, offset }) => {
  if (_isEmpty(categories)) {
    return {}
  }
  return gql`
    query PostsByCategory {
      ${
        generatePostsByCategory({
          limit,
          categories,
          offset
        })
      }
    }
    ${postsFragment}
  `
}

export const postsQuery = gql`
  query PostsQuery (
    $preview: Boolean!,
    $limit: Int!,
    $category: String,
    $slug: String,
    $author: String,
    $slug_not: String,
    $tags: [String],
    $authorUrl: String,
    $offset: Int
  ) {
    posts: newsCollection(
      order: [sys_firstPublishedAt_DESC],
      preview: $preview,
      limit: $limit,
      skip: $offset,
      where: {
        category: $category,
        slug: $slug,
        author: {
          name: $author,
          authorUrl: $authorUrl
        },
        slug_not: $slug_not,
        contentfulMetadata: {
          tags: {
            id_contains_some: $tags
          }
        }
      }
    ) {
      total
      items {
        ...postsFragment
      }
    }
  }
  ${postsFragment}
`

export const postQuery = gql`
  query PostQuery(
    $preview: Boolean!,
    $limit: Int!,
    $category: String,
    $slug: String
  ) {
    post: newsCollection(
      order: [sys_firstPublishedAt_DESC],
      preview: $preview,
      limit: $limit,
      where: {
        category: $category,
        slug: $slug
      }
    ) {
      total
      items {
        ...postFragment
      }
    }
    latestNews: newsCollection(
      order: [sys_firstPublishedAt_DESC],
      preview: $preview,
      limit: 5,
      where: {
        slug_not: $slug
      }
    ) {
      total
      items {
        ...postsFragment
      }
    }
  }
  ${postFragment}
  ${postsFragment}
`

export const latestPostQuery = gql`
  query LatestNews (
    $slug_not_in: [String],
    $preview: Boolean!
  ) {
    posts: newsCollection(
      order: [sys_firstPublishedAt_DESC],
      preview: $preview,
      limit: 4,
      where: {
        slug_not_in: $slug_not_in
      }
    ) {
      total
      items {
        ...postsFragment
      }
    }
  }
  ${postsFragment}
`

export const categoryQuery = gql`
  query (
    $preview: Boolean!,
    $limit: Int!,
    $slug: String,
    $id: String,
    $name: String
  ) {
    category: categoryCollection (
      preview: $preview,
      limit: $limit,
      where: {
        slug: $slug,
        id: $id,
        name: $name
      }
    ) {
      total
      items {
        name
        id
        slug
        subTitle
        description
      }
    }
  }
`

export const authorDataQuery = gql`
  query (
    $preview: Boolean!, 
    $limit: Int!, 
    $slug: String, 
    $postLimit: Int!
  ) {
    author: authorCollection (
      preview: $preview,
      limit: $limit,
      where: {
        authorUrl: $slug
      }
    ) {
      total
      items {
        ...authorFragment
      }
    }
    posts: newsCollection(
      order: [sys_firstPublishedAt_DESC],
      preview: $preview,
      limit: $postLimit,
      where: {
        author: {
          authorUrl: $slug
        }
      }
    ) {
      total
      items {
        ...postsFragment
      }
    }
  }
  ${authorFragment}
  ${postsFragment}
`
