import gql from 'graphql-tag'

export const postsFragment = gql`
  fragment postsFragment on News {
    title
    titleRichText {
      json
    }
    slug
    vertical
    mainContent {
      json
    }
    author {
      name
      authorUrl
    }
    featureImage {
      title
      width
      height
      url
    }
    date
    sys {
      publishedAt
      firstPublishedAt
    }
    excerpt
  }
`

export const postFragment = gql`
  fragment postFragment on News {
    slug
    title
    titleRichText {
      json
    }
    category
    vertical
    contentfulMetadata {
      tags {
        name
        id
      }
    }
    author {
      name
      sys {
        id
      }
      authorProfile
      authorUrl
      authorImage {
        url
        width
        height
      }
      twitter
      linkedin
      email
      authorJobTitle
    }
    date
    sys {
      publishedAt
      firstPublishedAt
    }
    featureImage {
      url
      width
      height
      description
      title
    }

    mainContent {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            url
            width
            height
            description
            title
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ... on TextBox {
              title
              theme
              content {
                json
              }
              id
              class
            }
            __typename
            ... on InlineComponent {
              componentName
              componentClass
              customComponent
              componentName
              componentId
            }
            __typename
            ... on InlineCta {
              ctaName
              ctaTitle
              ctaSubtitle {
                json
              }
              ctaType
              ctaUrl
              ctaId
              ctaClass
              ctaTheme
            }
            __typename
            ... on KeyPoints {
              keyPointsTitle
              keyPointsList {
                json
              }
              keyPointsTheme
            }
            __typename
            ... on FaqItem {
              question {
                json
              }
              answer {
                json
              }
            }
            __typename
            ... on VideoAsset {
              name
              videoUrl
              video {
                url
              }
            }
          }
          inline {
            sys {
              id
            }
            __typename
            ... on News {
              slug
              title
            }
          }
        }
      }
    }
    metaDescription
    seoTitle
    noIndex
    noCanonicalLink
    canonicalLink
    timeToRead
    hideFaqSchema
    faqSchema
  }
`

export const authorFragment = gql`
  fragment authorFragment on Author {
    name
    authorUrl
    authorImage {
      width
      height
      url
      title
    }
    email
    linkedin
    twitter
    authorProfile
    authorJobTitle
    faqSchema
  }
`
